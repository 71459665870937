<script lang="ts" setup>
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { type VideoList } from "@/utils/types";
import type { IAdBlock } from '@/api/page'
const props = defineProps({
  data: {
    type: Array as PropType<IAdBlock[]>,
    default: [],
  },
})

SwiperCore.use([Autoplay, Pagination]);

const currentIndex: Ref<number> = ref(0);

const swiperSlideSelector = ref();
const swiperSlideSelectorMobile = ref();
const onSwiper = (data: any) => {
  //存放 swiper
  swiperSlideSelector.value = data;
};

const swiperTo = (index: number) => {
  swiperSlideSelector.value.slideTo(index);
  swiperSlideSelectorMobile.value.slideTo(index);
};

const handelSwiperChange = (data: any) => {
  currentIndex.value = data.realIndex;
};

const handleSlidePrev = () => {
  swiperSlideSelector.value.slidePrev();
  swiperSlideSelectorMobile.value.slidePrev();
};
const handleSlideNext = () => {
  swiperSlideSelector.value.slideNext();
  swiperSlideSelectorMobile.value.slideNext();
};

const lists = computed<VideoList[]>(() =>
  (props.data || []).map((item) => {
    let yt = ''
    let ytimg = ''
    if (item.type === 'yt_video') {
      const videoId = extractVideoID(item.video_url)
      yt = `https://www.youtube.com/embed/${videoId}?controls=0&autoplay=1&mute=1&loop=1&playlist=${videoId}&hd=1`
      ytimg = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
    }
    return {
      type: item.type,
      image: `${import.meta.env.VITE_STORAGE_URL}/${item.image}`,
      video: `${import.meta.env.VITE_STORAGE_URL}/${item.video}`,
      ytvideo: yt,
      ytimage: ytimg,
      title: item.title,
      link: item.url,
    };
  })
)
</script>

<template>
  <section class="section">
    <ul class="lists">
      <li v-for="(item, index) in lists" :key="index">
        <div class="lists__item" v-if="item.type === 'image'">
          <nuxt-link :to="item.link" target="_blank">
            <figure class="lists__pic">
              <NuxtImg :src="item.image"></NuxtImg>
            </figure>
          </nuxt-link>
        </div>
        <div class="lists__item" v-if="item.type === 'video'">
          <nuxt-link :to="item.link" target="_blank">
            <video class="lists__video" autoplay loop muted playsinline>
              <source :src="item.video">
            </video>
          </nuxt-link>
        </div>
        <div class="lists__item lists__ytvideo" v-if="item.type === 'yt_video'">
          <nuxt-link :to="item.link" target="_blank">
            <div class="yt-mask"></div>
            <iframe :src="item.ytvideo" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
          </nuxt-link>
        </div>
      </li>
    </ul>
    <div class="swiper">
      <swiper :autoplay="{
        delay: 3000,
      }" :slides-per-view="1" :space-between="0" :speed="300" :loop="true" :pagination="true" class="swiper-list"
        @swiper="onSwiper" @slide-change="handelSwiperChange">
        <swiper-slide v-for="(item, index) in lists" :key="index">
          <div class="swiper-list__box" v-if="item.type === 'image'">
            <nuxt-link :to="item.link" target="_blank">
              <figure class="swiper-list__pic">
                <NuxtImg :src="item.image"></NuxtImg>
              </figure>
            </nuxt-link>
          </div>
          <div class="swiper-list__box" v-if="item.type === 'video'">
            <nuxt-link :to="item.link" target="_blank">
              <div class="swiper-list__pic">
                <video autoplay loop muted playsinline>
                  <source :src="item.video">
                </video>
              </div>
            </nuxt-link>
          </div>
          <div class="swiper-list__box" v-if="item.type === 'yt_video'">
            <nuxt-link :to="item.link" target="_blank">
              <div class="swiper-list__pic swiper-list__ytvideo">
                <div class="yt-mask"></div>
                <iframe :src="item.ytvideo" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
              </div>
            </nuxt-link>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section {
  margin-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;

  @include max-media(767) {
    margin-bottom: 70px;
  }
}

.lists {
  display: flex;
  flex-wrap: wrap;
  margin-left: -52px;
  margin-right: -52px;

  @include max-media(767) {
    display: none;
  }

  li {
    width: 33.333%;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 104px;
  }

  &__item {
    position: relative;

    &:hover .lists__btn {
      opacity: 1;
      cursor: pointer;
    }
  }

  &__pic {
    img {
      display: block;
      width: 100%;
    }
  }

  &__video {
    display: block;
    width: 100%;
  }

  &__ytvideo {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* 16:9 Aspect Ratio */
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .yt-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__btn {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin-left: -21px;
    margin-top: -21px;
    width: 42px;
    height: 42px;
    color: $color-white-1;
    opacity: 0;
  }
}

.swiper {
  overflow: unset;
  max-width: 275px;

  @include min-media(768) {
    display: none;
  }
}

.swiper-list {
  &__box {
    position: relative;
  }

  &__pic {
    img {
      display: block;
      width: 100%;
    }

    video {
      display: block;
      width: 100%;
    }
  }

  &__ytvideo {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* 16:9 Aspect Ratio */
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .yt-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__btn {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin-left: -21px;
    margin-top: -21px;
    width: 42px;
    height: 42px;
    color: $color-white-1;
  }
}

:deep(.swiper-pagination) {
  bottom: -35px;
}

:deep(.swiper-pagination-bullet) {
  background: #d9d9d9;
  opacity: 1;
}

:deep(.swiper-pagination-bullet-active) {
  background: #000;
  margin: 0 11px !important;
}

:deep(.swiper-slide) {
  opacity: 0;
}

:deep(.swiper-slide-active) {
  opacity: 1;
}
</style>
